$(function() {
    if (location.search && location.search.length) {
        sessionStorage['search'] = location.search;
    }

    if (sessionStorage['search']) {
        $('[data-add-search]').each(function () {
            let href = $(this).attr('href');
            href += sessionStorage['search'];
            $(this).attr('href', href);
        });
    }

    $('.external-link').on('click', function (e) {
        e.preventDefault();
        $('#exit-yes').attr('href', $(this).attr('href'));
        $('#exit-interstitial').fadeIn(function() {
            trapFocus($(this)[0]);
        });
    });

    $('#exit-no, #exit-interstitial').on('click', function (e) {
        e.preventDefault();
        $('#exit-interstitial').fadeOut();
    });

    $('#exit-yes').on('click', function () {
        $('#exit-interstitial').fadeOut();
    });

    $('#exit-interstitial-content').on('click', function (e) {
        e.stopPropagation();
    });

    $('#exit-interstitial').on('keydown', function (e) {
        if(e.key === "Escape") {
            $(this).fadeOut();
        }
    })
});

function trapFocus(element) {
    var focusableEls = $(element).find('a[href]:not([disabled]):visible');
    var firstFocusableEl = focusableEls[0];
    var lastFocusableEl = focusableEls[focusableEls.length - 1];
    var KEYCODE_TAB = 9;
    firstFocusableEl.focus();

    element.addEventListener('keydown', function (e) {
        var isTabPressed = (e.key === 'Tab' || e.keyCode === KEYCODE_TAB);
        if (!isTabPressed) {
            return;
        }
        if (e.shiftKey) /* shift + tab */ {
            if (document.activeElement === firstFocusableEl) {
                lastFocusableEl.focus();
                e.preventDefault();
            }
        } else /* tab */ {
            if (document.activeElement === lastFocusableEl) {
                firstFocusableEl.focus();
                e.preventDefault();
            }
        }
    });
}